<script lang="ts">
  import type { SvelteComponent } from "svelte";
  import { reportUserEvent, USER_EVENTS } from "@ui/reportEvents";

  import FacebookSVG from "./FacebookSVG.svelte";
  import LinkedInSVG from "./LinkedInSVG.svelte";
  import PinterestSVG from "./PinterestSVG.svelte";
  import { getSocialButtonContext } from "./socialContext";
  import TwitterSVG from "./TwitterSVG.svelte";
  type SuportedSocialNetworks = "facebook" | "twitter" | "linkedin" | "pinterest";

  type ServiceFunction = (url: string, message: string, imageUrl?: string, countUrl?: string) => string;

  const ServiceMap: { [service in SuportedSocialNetworks]: ServiceFunction } = {
    facebook: getFacebookUrl,
    twitter: getTwitterUrl,
    pinterest: getPinterestUrl,
    linkedin: getLinkedinUrl
  };

  const ButtonTextMap: { [P in SuportedSocialNetworks]: string } = {
    facebook: "Facebook",
    twitter: "Twitter",
    linkedin: "Share",
    pinterest: "Pinterest"
  };

  const UserEventMap: { [P in SuportedSocialNetworks]: string } = {
    facebook: USER_EVENTS.PreviewAndShareShareWithFacebookClicked,
    twitter: USER_EVENTS.PreviewAndShareShareWithTwitterClicked,
    linkedin: USER_EVENTS.PreviewAndShareShareWithLinkedinClicked,
    pinterest: USER_EVENTS.PreviewAndShareShareWithPinterestClicked
  };

  const IconMap: { [P in SuportedSocialNetworks]: typeof SvelteComponent } = {
    facebook: FacebookSVG,
    twitter: TwitterSVG,
    linkedin: LinkedInSVG,
    pinterest: PinterestSVG
  };

  export let socialMedia: SuportedSocialNetworks;
  export let colorOnHover: boolean = true;

  const context = getSocialButtonContext();

  function getFacebookUrl(url: string, message: string, imageUrl?: string, countUrl?: string) {
    return `http://www.facebook.com/sharer.php?u=${countUrl || url}&t=${message}`;
  }

  function getTwitterUrl(url: string, message: string, imageUrl?: string, countUrl?: string) {
    const msg = `Check out ${message} (via www.smore.com)`;
    return `https://x.com/share?url=${url}&counturl=${countUrl}&text=${msg}&related=smorepages`;
  }

  function getPinterestUrl(url: string, message: string, imageUrl: string = "") {
    return `http://pinterest.com/pin/create/button/?url=${url}&description=${message}&media=${imageUrl}`;
  }

  function getLinkedinUrl(url: string, message: string) {
    return `https://www.linkedin.com/cws/share?url=${url}`;
  }

  function shareClicked(e: MouseEvent) {
    const el = e.target! as HTMLButtonElement;
    const service = el.dataset.service as SuportedSocialNetworks;
    if (service) {
      const func = ServiceMap[service];
      reportUserEvent(UserEventMap[service], {
        newsletterId: context.newsletterId
      });
      if (func) {
        var shareUrl = func(
          encodeURIComponent(context.shortUrl),
          encodeURIComponent(context.metaTitle),
          encodeURIComponent(context.screenshotUrl),
          encodeURIComponent(context.canonicalUrl)
        );
        window.open(shareUrl, "", "height=432,width=640");
      }
    }
  }
</script>

<button
  aria-label={`Share with ${socialMedia}`}
  class="clickable group flex w-full items-center justify-center bg-white px-4 py-2.5 text-13 font-medium outline-none transition-all hover:text-white focus:outline-none {socialMedia}"
  class:text-white={!colorOnHover}
  style={colorOnHover ? "" : "background-color: var(--brand-color);"}
  data-service={socialMedia}
  on:click={shareClicked}>
  <div
    class="pointer-events-none group-hover:text-white"
    class:text-white={!colorOnHover}
    style={colorOnHover ? "" : "--brand-color:white !important"}>
    <svelte:component this={IconMap[socialMedia]} />
  </div>
  <span class="pointer-events-none ml-1">{ButtonTextMap[socialMedia]}</span>
</button>

<style>
  button {
    height: var(--height, "auto");
    --brand-color: white;
    width: var(--social-button-width, inherit);
  }
  button > div {
    color: var(--brand-color);
  }

  button:hover {
    background-color: var(--brand-color);
  }

  button.facebook {
    --brand-color: #1877f2;
  }

  button.linkedin {
    --brand-color: #0a66c2;
  }

  button.pinterest {
    --brand-color: #e60019;
  }

  button.twitter {
    --brand-color: #1da1f2;
  }
</style>
